import './MessageReaction.css';
import {ALL_REACTIONS, Reaction} from '../../../../../shared/reaction';
import {ProfilePicture} from '../../../../Utils/ProfilePicture';
import {useAppSelector} from '../../../../../redux/hooks';
import {genderImage} from '../../../../../constants/genderImage';

interface Props {
    reactionId: Reaction;
    users: string[];
    isSentByMe: boolean;
}

const AVATAR_SIZE = 18;

const MessageReaction = (props: Props): JSX.Element => {
    const {isSentByMe, reactionId, users} = props;
    const usersProfilePictures = useAppSelector((state) => {
        const result: string[] = [];
        for (const uid of users) {
            if (uid === state.user.userId) {
                result.push(state.user.data.settings.public?.profilePicture ?? genderImage[state.user.data.settings.public?.gender ?? 'dunno']);
            } else {
                const partner = state.partners.partners[uid];
                result.push(partner.publicSettings?.profilePicture ?? genderImage[partner.publicSettings?.gender ?? 'dunno']);
            }
        }
        return result;
    });
    return <div
        className={`MessageReaction-container ${isSentByMe ? 'mine' : 'partner'}`}
    >
        <div className={'MessageReaction-emoji'}>{ALL_REACTIONS[reactionId]}</div>
        <div className={'MessageReaction-emoji'}>
            {usersProfilePictures.map((src, idx) => <ProfilePicture
            profilePictureUrl={src}
            isClickable={false}
            hasPremium={false}
            height={AVATAR_SIZE}
            width={AVATAR_SIZE}
            extraStyles={{
                margin: 0,
                zIndex: idx,
                marginLeft: -AVATAR_SIZE * idx * 0.5
            }}
        />)}
        </div>
    </div>
}

export default MessageReaction;