import './MessageInput.css';
import React, {FormEvent, forwardRef, memo, ReactElement, useCallback, useEffect} from 'react';
import {MessagePreview} from './MessagePreview';
import {MessagePreview as MessagePreviewType} from '../../../../types/messagePreview';
import {sendTypingEvent} from '../../../../utils/helpers/sendTypingEvent';
import {RecordButtonProps} from '../../../../types/recorder';
import {MessageInputRecording} from './MessageInputRecording';
import {store} from '../../../../redux/store';
import {useAppDispatch, useAppSelector} from '../../../../redux/hooks';
import {updateDraft, updateMessagePreview} from '../../../../redux/reducers/localSettingsReducer';

interface Props extends RecordButtonProps {
    dialogId: string;
    messageText: string;
    disabled: boolean;
    setMessageText: (newText: string) => void;
    onClick: () => void;
    messagePreviewState: MessagePreviewType | null;
}

export const MessageInput = memo(
    forwardRef<HTMLDivElement, Props>((props, ref): ReactElement => {
        const {dialogId, setMessageText, onClick, disabled, messagePreviewState, recorderState, handlers} = props;
        const userId = useAppSelector((state) => state.user.userId);
        const dispatch = useAppDispatch();
        const handleInput = useCallback(
            (e: FormEvent<HTMLDivElement>) => {
                sendTypingEvent(dialogId, 'typing');
                const newText = e.currentTarget.innerText.trim() ?? '';
                setMessageText(newText);
                dispatch(updateDraft({draft: newText, dialogId}));
            },
            [setMessageText, dialogId, dispatch]
        );

        if (recorderState.initRecording) {
            return <MessageInputRecording recorderState={recorderState} handlers={handlers} />;
        }

        const onArrowUp = (): void => {
            const messages = store.getState().dialogs.messages[dialogId];
            if (!messages) {
                return;
            }
            const userMessagesWithText = Object.values(messages).filter((msg) => {
                return msg.sender === userId && 'text' in msg && !msg?.deletedFor?.includes(userId);
            });

            if (userMessagesWithText.length > 0) {
                const lastUserMessageMsgId = userMessagesWithText[userMessagesWithText.length - 1].msgId;
                dispatch(
                    updateMessagePreview({
                        action: 'edit',
                        messageId: lastUserMessageMsgId,
                        dialogId: dialogId,
                    })
                );
            }
        };

        return (
            <div className={'MessageInput-container'}>
                <MessagePreview dialogId={dialogId} messagePreviewState={messagePreviewState} />
                <div
                    ref={ref}
                    className={`MessageInput-input ${messagePreviewState && 'active-preview'}`}
                    onInput={handleInput}
                    contentEditable="true"
                    suppressContentEditableWarning={true}
                    onKeyDown={(e) => {
                        if (e.key === 'ArrowUp' && e.currentTarget.innerText.length === 0) {
                            onArrowUp();
                            return;
                        }

                        if (disabled) return;
                        // Send message on enter
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            onClick();
                            e.currentTarget.innerText = '';
                        }
                    }}
                />
            </div>
        );
    })
);
