export const ALL_REACTIONS = {
    love: '❤️',
    fire: '🔥',
    laugh: '😂',
    like: '👍',
    devil: '😈',
    cry: '😭',
    hundred: '💯',
    praise: '🙌',
    crazy: '🤪',
    dislike: '👎',
    clown: '🤡',
    peach: '🍑',
    moai: '🗿',
    sleep: '😴',
    cherries: '🍒',
    poop: '💩',
    scream: '😱',
    think: '🤔',
    moon: '🌚',
    kiss: '💋',
    eyes: '👀',
    nail_polish: '💅',
    mind_blown: '🤯',
    broken_heart: '💔',
    heart_eyes: '😍',
    party: '🥳',
    banana: '🍌',
} as const;

export type Reaction = keyof typeof ALL_REACTIONS;
export type Emoji = typeof ALL_REACTIONS[Reaction];
