import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Meta} from '../../shared/callback-types';
import {MessageAny} from '../../shared/message-any';
import {UserReaction} from '../../shared/user-reaction';

type Dialogs = {
    metas: { [dialogId: string]: Meta };
    messages: { [dialogId: string]: { [messageId: string]: MessageAny } };
    lastMessageReceivedAt: number;
};

const initialState: Dialogs = {metas: {}, messages: {}, lastMessageReceivedAt: 0};

export const dialogsSlice = createSlice({
    name: 'dialogs',
    initialState: initialState,
    reducers: {
        resetDialogs: () => initialState,
        addDialog: (state, action) => {
            const {dialogId, meta, partnerId} = action.payload;
            meta.partner = partnerId;
            meta.removed = false;
            state.metas[dialogId] = meta;
            state.messages[dialogId] = {};
        },
        addMessage: (state, action) => {
            const {dialogId, message} = action.payload;
            // Ignore uploading messages because of differences in API for web and mobile
            if (message.status === 'uploading') {
                return;
            }
            message.createdAt = _serializeIfNeeded(message.createdAt);
            message.lastUpdatedAt = _serializeIfNeeded(message.lastUpdatedAt);
            state.lastMessageReceivedAt = Math.max(
                state.lastMessageReceivedAt,
                new Date(message?.lastUpdatedAt)?.getTime() ?? 0
            );
            state.messages[dialogId][message.msgId] = message;
        },
        closeDialog: (state, action) => {
            const {dialogId} = action.payload;
            if (state.metas[dialogId]) {
                state.metas[dialogId].active = false;
            }
        },
        updateMeta: (state, action) => {
            const {dialogId, meta} = action.payload;
            state.metas[dialogId] = Object.assign(state.metas[dialogId], meta);
        },
        readMessages: (state, action) => {
            const {dialogId, messagesIds} = action.payload;
            for (const msgId of messagesIds) {
                if (state.messages[dialogId] && state.messages[dialogId][msgId]) {
                    state.messages[dialogId][msgId].status = 'read';
                }
            }
        },
        makeDialogPermanent: (state, action) => {
            const {dialogId} = action.payload;
            if (state.metas[dialogId]) {
                state.metas[dialogId].temporary = false;
            }
        },
        removeDialog: (state, action) => {
            const {dialogId} = action.payload;
            if (state.metas[dialogId]) {
                state.metas[dialogId].removed = true;
            }
        },
        deleteDialog: (state, action) => {
            const {dialogId} = action.payload;
            if (state.metas[dialogId]) {
                state.metas[dialogId].deleted = true;
            }
        },
        updateMutedFor: (state, action) => {
            const {dialogId, mutedFor} = action.payload;
            if (state.metas[dialogId]) {
                state.metas[dialogId].mutedFor = mutedFor;
            }
        },
        updateReactions: (state, action: PayloadAction<{
            dialogId: string,
            messageId: string,
            reactions: UserReaction[]
        }>) => {
            const {dialogId, messageId, reactions} = action.payload;
            if (state.messages[dialogId] && state.messages[dialogId][messageId]) {
                state.messages[dialogId][messageId] = {
                    ...state.messages[dialogId][messageId],
                    reactions: reactions
                } as MessageAny;
            }
        }
    },
});

const _serializeIfNeeded = (data: Date | string): string => {
    if (typeof data === 'object') {
        return data.toISOString();
    }
    return data;
};

// Action creators are generated for each case reducer function
export const {
    addDialog,
    addMessage,
    closeDialog,
    updateMeta,
    readMessages,
    makeDialogPermanent,
    removeDialog,
    deleteDialog,
    updateMutedFor,
    resetDialogs,
    updateReactions,
} = dialogsSlice.actions;

export const dialogsReducer = dialogsSlice.reducer;
