import './Message.css';
import _ from 'lodash';
import React, {memo, MouseEvent, ReactElement, useMemo} from 'react';
import {MessageAny} from '../../../../shared/message-any';
import {MessageContent} from './MessageContent/MessageContent';
import {useAppDispatch, useAppSelector} from '../../../../redux/hooks';
import {MessageInfo} from './MessageInfo/MessageInfo';
import {showPopOverAt} from '../../../../redux/reducers/localSettingsReducer';
import {isUserMessage} from '../../../../utils/message/isUserMessage';
import {calculatePopOverOffset} from '../../../../utils/helpers/calculatePopOverOffset';
import {MessageReply} from './MessageReply';
import {checkReply} from '../../../../utils/message/checkMessage';
import {MessageDateSeparator} from './MessageDateSeparator';
import MessageReactions from './MessageReactions/MessageReactions';

interface Props {
    dialogId: string;
    message: MessageAny;
    shouldShowDaySeparator: boolean;
}

export const Message = memo(
    (props: Props): ReactElement => {
        const {message, dialogId, shouldShowDaySeparator} = props;
        const isSentByMe = useAppSelector((state) => state.user.userId === message.sender);
        const repliedMessage = useAppSelector((state) => {
            if (!checkReply(message) && message.replyTo) {
                return state.dialogs.messages[dialogId][message.replyTo];
            }
        });
        const dispatch = useAppDispatch();
        const shouldShowReactions = 'reactions' in message;
        const bubbleStyles = useMemo(() => {
            const styles = ['Message-common-bubble'];
            if (message.type === 'text') {
                styles.push('Message-text-bubble');
            }
            if (message.type === 'system') {
                styles.push('Message-system-bubble');
            } else if (message.type === 'about-me') {
                styles.push('Message-about-me-bubble');
            } else {
                if (isSentByMe) {
                    styles.push('Message-user-bubble hoverable');
                } else {
                    styles.push('Message-partner-bubble hoverable');
                }
            }

            if (message.type === 'image') {
                styles.push('Message-image-bubble hoverable');
            }

            return styles.join(' ');
        }, [isSentByMe, message.type]);

        const containerStyles = useMemo(() => {
            const styles = ['Message-container'];
            if (message.type === 'system') {
                styles.push('system');
            } else if (message.type === 'about-me') {
                styles.push('about-me');
            } else {
                if (isSentByMe) {
                    styles.push('user');
                } else {
                    styles.push('partner');
                }
            }
            return styles.join(' ');
        }, [isSentByMe, message.type]);

        const onMessageClick = (event: MouseEvent<HTMLDivElement>) => {
            event.preventDefault();
            if (isUserMessage(message.type)) {
                let point = {
                    x: event.pageX,
                    y: event.pageY,
                };

                point = calculatePopOverOffset({
                    clicked: point,
                    messageWidth: event.currentTarget.clientWidth,
                });
                const popOverProps = {point, messageId: message.msgId};
                dispatch(showPopOverAt({popOverProps}));
            }
        };

        return (
            <>
                <div className={containerStyles}>
                    <div className={bubbleStyles} onClick={onMessageClick} onContextMenu={onMessageClick}>
                        <MessageReply repliedMessage={repliedMessage} isSentByMe={isSentByMe}/>
                        <MessageContent dialogId={dialogId} message={message} isSentByMe={isSentByMe}/>
                        <MessageInfo
                            messageType={message.type}
                            sentAt={message.createdAt}
                            status={message.status}
                            isSentByMe={isSentByMe}
                            isEdited={message.isEdited}
                        />
                    </div>
                    {shouldShowReactions &&
                        <MessageReactions reactions={message?.reactions ?? []} isMsgSentByMe={isSentByMe}/>}
                </div>
                <MessageDateSeparator isVisible={shouldShowDaySeparator} createdAt={message.createdAt}/>
            </>
        );
    },
    (prevProps, nextProps) => _.isEqual(prevProps, nextProps)
);
