import {v4 as uuidv4} from 'uuid';
import {getUserId} from '../user/getUserId';
import {BaseMessage} from '../../shared/base-message';
import {MessageImage} from '../../shared/message-image';
import {MessageText} from '../../shared/message-text';
import {MessageVoice} from '../../shared/message-voice';

export async function generateMessage(params: GenerateVoiceMessage): Promise<MessageVoice | null>;
export async function generateMessage(params: GenerateImageMessage): Promise<MessageImage | null>;
export async function generateMessage(params: GenerateTextMessage): Promise<MessageText | null>;
export async function generateMessage(
    params: GenerateMessageParams
): Promise<MessageText | MessageImage | MessageVoice | null> {
    const {type, text, width, height} = params;
    const userId = await getUserId();
    if (!userId) {
        return null;
    }

    const commonFields: Omit<BaseMessage, 'type'> = {
        msgId: uuidv4(),
        status: 'sending',
        createdAt: new Date(),
        lastUpdatedAt: new Date(),
        isEdited: false,
        sender: userId,
        replyTo: params.replyTo,
        textHistory: [],
        deletedFor: [],
    };

    switch (type) {
        case 'text': {
            return {
                text: text.trim(),
                type: type,
                receiver: [],
                ...commonFields,
            };
        }
        case 'image':
            return {
                uri: '',
                height: height,
                width: width,
                text: text?.trim() ?? '',
                type: type,
                receiver: [],
                madeWithCamera: false,
                secret: false,
                ...commonFields,
                status: 'uploading',
                oneTime: false,
                oneTimeWasViewedAt: null,
            };
        case 'voice':
            return {
                uri: '',
                type: type,
                receiver: [],
                ...commonFields,
                listened: false,
                status: 'uploading',
            };
        default:
            return null;
    }
}

type GenerateTextMessage = {
    type: 'text';
    text: string;
    replyTo?: string;
    width?: never;
    height?: never;
};

type GenerateImageMessage = {
    type: 'image';
    text?: string;
    replyTo?: string;
    width: number;
    height: number;
};

type GenerateVoiceMessage = {
    type: 'voice';
    replyTo?: string;
    width?: never;
    height?: never;
    text?: never;
};

type GenerateMessageParams = GenerateTextMessage | GenerateImageMessage | GenerateVoiceMessage;
